import { UNITS } from '.';
const NAME_SPACE = 'sanitaria';

// EVENTS
const GENERIC_EVENT = 'gestionsanitaria';
const VACUNACION = 'vacunacion';
const CONTROL_EPIDEMIOLOGICO = 'controlepidemiologico';
const SANIDAD_INDIVIDUAL = 'sanidadindividual';
const TRATAMIENTO_COLECTIVO = 'tratamientocolectivo';

const EVENT_TYPES = [
  VACUNACION,
  CONTROL_EPIDEMIOLOGICO,
  SANIDAD_INDIVIDUAL,
  TRATAMIENTO_COLECTIVO,
];

const EVENT_FORMATTED = {
  [VACUNACION]: 'Vacunación',
  [CONTROL_EPIDEMIOLOGICO]: 'Control epidemiológico',
  [SANIDAD_INDIVIDUAL]: 'Sanidad individual',
  [TRATAMIENTO_COLECTIVO]: 'Tratamientos colectivos',
};

const EVENT_DASHBOARD = {
  [VACUNACION]: {
    title: EVENT_FORMATTED[VACUNACION],
    description: 'unidades disponibles',
    url: VACUNACION,
  },
  [CONTROL_EPIDEMIOLOGICO]: {
    title: EVENT_FORMATTED[CONTROL_EPIDEMIOLOGICO],
    description: 'unidades disponibles',
    url: CONTROL_EPIDEMIOLOGICO,
  },
  [TRATAMIENTO_COLECTIVO]: {
    title: EVENT_FORMATTED[TRATAMIENTO_COLECTIVO],
    description: 'unidades disponibles',
    url: TRATAMIENTO_COLECTIVO,
  },
  [SANIDAD_INDIVIDUAL]: {
    title: EVENT_FORMATTED[SANIDAD_INDIVIDUAL],
    description: 'unidades disponibles',
    url: SANIDAD_INDIVIDUAL,
  },
};

const EVENT_UNIT_TYPES = {
  [VACUNACION]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [CONTROL_EPIDEMIOLOGICO]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [TRATAMIENTO_COLECTIVO]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [SANIDAD_INDIVIDUAL]: [UNITS.HEMBRAS, UNITS.MACHOS],
};

// NOEVENTS
const VACUNA = 'vacuna';
const PRODUCTO = 'producto';
const SINDROME = 'sindrome';
const OBJTIVOS_TRATAMIENTO = 'objetivostratamiento';

const NOEVENT_TYPES = { VACUNA, PRODUCTO, SINDROME };
const NOEVENT_TYPES_LIST = Object.values(NOEVENT_TYPES);

const NOEVENT_FORMATTED = {
  [VACUNA]: 'Vacunas',
  [PRODUCTO]: 'Productos',
  [SINDROME]: 'Sindromes',
  [OBJTIVOS_TRATAMIENTO]: 'Objetivos de tratamiento',
};

const NOEVENTS_DASHBOARD_INSUMOS = {
  [VACUNA]: {
    title: NOEVENT_FORMATTED[VACUNA],
    url: VACUNA,
  },
  [PRODUCTO]: {
    title: NOEVENT_FORMATTED[PRODUCTO],
    url: PRODUCTO,
  },
};

const NOEVENTS_DASHBOARD_CONFIG = {
  [SINDROME]: {
    title: NOEVENT_FORMATTED[SINDROME],
    url: SINDROME,
  },
  [OBJTIVOS_TRATAMIENTO]: {
    title: NOEVENT_FORMATTED[OBJTIVOS_TRATAMIENTO],
    url: OBJTIVOS_TRATAMIENTO,
  },
};

const SANITARIA = {
  NAME_SPACE,
  GENERIC_EVENT,
  VACUNACION,
  CONTROL_EPIDEMIOLOGICO,
  SANIDAD_INDIVIDUAL,
  TRATAMIENTO_COLECTIVO,
  VACUNA,
  PRODUCTO,
  SINDROME,
  OBJTIVOS_TRATAMIENTO,
  EVENT_TYPES,
  EVENT_FORMATTED,
  EVENT_DASHBOARD,
  EVENT_UNIT_TYPES,
  NOEVENT_TYPES,
  NOEVENT_TYPES_LIST,
  NOEVENT_FORMATTED,
  NOEVENTS_DASHBOARD_INSUMOS,
  NOEVENTS_DASHBOARD_CONFIG,
};

export default SANITARIA;
